import { render, staticRenderFns } from "./IdentificationWorkerColumn.vue?vue&type=template&id=87c9953c&scoped=true&"
import script from "./IdentificationWorkerColumn.vue?vue&type=script&setup=true&lang=ts&"
export * from "./IdentificationWorkerColumn.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./IdentificationWorkerColumn.vue?vue&type=style&index=0&id=87c9953c&prod&scoped=true&lang=scss&"
import style1 from "./IdentificationWorkerColumn.vue?vue&type=style&index=1&id=87c9953c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87c9953c",
  null
  
)

export default component.exports