var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{'IdentificationWorkerCard': true, light: _setup.props.mode === 'light', full: _setup.props.mode === 'full'}},[_c(_setup.Card,{attrs:{"disableScroll":true}},[_c('div',{staticClass:"identity"},[_c(_setup.Avatar,{staticClass:"round x-large",attrs:{"image":_setup.props.identity.picture}}),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"firstname-lastname"},[(_setup.props.displayValidity && _setup.props.identity.isValid)?_c(_setup.Tag,{class:{
                        'success': _setup.props.identity.isValid,
                        'error': !_setup.props.identity.isValid,
                    }},[(true)?_c(_setup.NewIcon,{attrs:{"icon":"design-system-check"}}):_vm._e(),(false)?_c(_setup.NewIcon,{attrs:{"icon":"design-system-exclamation-mark"}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"text-xl medium"},[_vm._v(_vm._s(_vm.identity.firstname)+" "+_vm._s(_vm.identity.lastname))])],1),(_setup.props.displayOrigin)?_c('div',{staticClass:"origin-badges-container"},[(_setup.currentAgency.isInMyAgency)?_c(_setup.Tooltip,{attrs:{"position":'bottom-left',"arrow":'top',"mode":"click"},scopedSlots:_vm._u([{key:"trigger-zone",fn:function(){return [_c('div',{staticClass:"section candidate-job"},[_c('span',{staticClass:"jobs text-xm medium"},[_vm._v(_vm._s(_setup.currentAgency.label))])])]},proxy:true}],null,false,3175516640)}):_vm._e(),(_setup.othersAgencies.label !== '')?_c(_setup.Tooltip,{attrs:{"position":'bottom-left',"arrow":'top',"mode":"hover"},scopedSlots:_vm._u([{key:"trigger-zone",fn:function(){return [_c('div',{staticClass:"section candidate-job"},[_c('span',{staticClass:"jobs text-xm medium"},[_vm._v(_vm._s(_setup.othersAgencies.label))])])]},proxy:true},{key:"tooltip-content",fn:function(){return _vm._l((_setup.othersAgencies.details),function(el){return _c('p',{staticClass:"text-xs medium"},[_vm._v(_vm._s(el))])})},proxy:true}],null,false,3741832139)}):_vm._e()],1):_vm._e()])],1),_vm._l((_setup.props.fields),function(field,fieldIndex){return _c('div',{key:fieldIndex,staticClass:"field"},[_c('div',{staticClass:"validityTag"},[(_setup.props.displayValidity)?_c(_setup.Tag,{class:{
                    'success': field.isSameValue,
                    'error': field.isSameValue === false,
                    'undefined': field.isSameValue === undefined,
                }},[(field.isSameValue)?_c(_setup.NewIcon,{attrs:{"icon":"design-system-check"}}):_c(_setup.NewIcon,{attrs:{"icon":"design-system-exclamation-mark"}})],1):_vm._e()],1),_c('div',{staticClass:"content"},[(_setup.props.mode === 'full')?_c('label',{staticClass:"field-label text-xs medium"},[_vm._v(_vm._s(field.label))]):_vm._e(),_c('p',{staticClass:"text-md medium"},[_vm._v(_vm._s(field.value))])])])}),_c('div',{staticClass:"card-actions-container"},[_vm._t("action-container")],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }